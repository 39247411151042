import { FooterLinkProps, SocialCTA } from "@components/NewLayout";
import Image from "next/image";
import React, { FC } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
interface DesktopFooterProps {
    noMarginTop: boolean;
    handleFooterLinkV2: (data: any) => FooterLinkProps;
    customFooterTop?: boolean;
}

const footerLinkData1 = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "Partner with us",
                        externalLink: "/gallabox-partner-program",
                        isNew: true,
                    },
                    {
                        name: "Pricing",
                        link: "/pricing",
                    },
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://angel.co/company/gallabox",
                    },

                    {
                        name: "Contact Us",
                        link: "/contact",
                    },
                    {
                        name: "Watch Demo",
                        externalLink: "/watch-demo",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Product",
                items: [
                    {
                        name: "AI WhatsApp Template Generator",
                        link: "/ai-whatsapp-template-generator",
                    },
                    {
                        name: "WhatsApp Pricing Calculator",
                        link: "/whatsapp-conversation-pricing-calculator",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp Business Solution",
                        link: "/whatsapp-business-api",
                    },
                    {
                        name: "WhatsApp Chat Widget",
                        link: "/whatsapp-chat-button",
                    },
                    {
                        name: "WhatsApp Link Generator",
                        link: "/whatsapp-link-generator",
                    },
                    {
                        name: "WhatsApp QR Code Generator",
                        link: "/whatsapp-qr-code-generator",
                    },
                    {
                        name: "WhatsApp Chatbot Template",
                        link: "/whatsapp-chatbot-templates",
                    },
                ],
            },
        ],
    },
    {
        title: "Column3",
        columnItems: [
            {
                header: "Industry",
                items: [
                    {
                        name: "Education",
                        link: "/whatsapp-chatbot-for-edutech",
                    },
                    {
                        name: "Travel",
                        link: "/whatsapp-chatbot-for-travel-and-tourism",
                    },
                    {
                        name: "Real Estate",
                        link: "/whatsapp-chatbot-for-real-estate",
                        isNew: true,
                    },
                    {
                        name: "BFSI",
                        link: "/banking-chatbots",
                    },
                    {
                        name: "Ecommerce",
                        link: "/whatsapp-integrations/ecommerce",
                    },
                    {
                        name: "Healthcare",
                        link: "/whatsapp-chatbot-for-health-wellness-brands",
                    },
                ],
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Learn",
                items: [
                    {
                        name: "Whatsapp Marketing in 2024",
                        externalLink: "/blog/whatsapp-marketing-for-businesses-in-2024-how-to-get-started",
                    },
                    {
                        name: "Effective WhatsApp Ads",
                        externalLink: "/blog/whatsapp-ads",
                    },
                    {
                        name: "Create WhatsApp Chatbot",
                        externalLink: "/blog/create-whatsapp-chatbot",
                    },
                    {
                        name: "What is WhatsApp Flows?",
                        externalLink: "/blog/what-is-whatsapp-flows",
                    },
                    {
                        name: "WhatsApp Broadcasting Tips",
                        externalLink: "/blog/whatsapp-broadcast",
                    },
                ],
            },
        ],
    },
];

const footerLinkData2 = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Compare",
                items: [
                    {
                        name: "Gallabox vs AiSensy",
                        link: "/best-aisensy-alternative",
                        isNew: true,
                    },
                    {
                        name: "Gallabox vs Rasayel",
                        link: "/best-rasayel-alternative",
                        isNew: true,
                    },
                    {
                        name: "Gallabox vs Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "Gallabox vs Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "Gallabox vs Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "Gallabox vs Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "Gallabox vs Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "Gallabox vs GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Features",
                items: [
                    {
                        name: "WhatsApp Flows",
                        link: "/whatsapp-flows",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp AI Chatbot",
                        link: "/whatsapp-ai-chatbot",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp Shop",
                        link: "/build-your-shop-on-whatsapp",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/whatsapp-business-api",
                    },

                    {
                        name: "WhatsApp Chatbot",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/whatsapp-broadcast",
                    },
                    {
                        name: "Click to WhatsApp Ads",
                        link: "/click-to-whatsapp-ads",
                    },

                    {
                        name: "Shared Team Inbox",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "Drip Marketing",
                        link: "/whatsapp-drip-marketing",
                    },
                    {
                        name: "WhatsApp Payments",
                        link: "/whatsapp-payments",
                    },
                ],
            },
        ],
    },
    {
        title: "Column3",
        columnItems: [
            {
                header: "Integration",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                        isNew: true,
                    },
                    {
                        name: "Zoho Books",
                        link: "/whatsapp-integrations/zoho-books",
                        isNew: true,
                    },
                    {
                        name: "LeadSquared",
                        link: "/whatsapp-integrations/lead-squared",
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                        isNew: true,
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Woocommerce",
                        link: "/whatsapp-integrations/woocommerce",
                    },
                    {
                        name: "Zoho",
                        link: "/whatsapp-integrations/zoho",
                    },

                    {
                        name: "Facebook Lead Ads",
                        link: "/whatsapp-integrations/facebook-leads-to-whatsapp",
                    },
                    {
                        name: "Zapier ",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "Hubspot ",
                        link: "/whatsapp-integrations/hubspot",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "Google Sheets",
                        link: "/whatsapp-integrations/google-sheets",
                    },
                ],
                goToNextColumn: true,
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Support",
                items: [
                    {
                        name: "FAQs",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                    {
                        name: "Raise a ticket",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                    {
                        name: "Cookie Policy",
                        link: "/cookie-policy",
                    },
                    {
                        name: "Privacy Policy",
                        link: "/privacy-policy",
                    },
                    {
                        name: "Terms & Conditions",
                        link: "/terms",
                    },
                ],
            },
            {
                header: "Resources",
                items: [
                    {
                        name: "Docs",
                        externalLink: "https://docs.gallabox.com/",
                    },
                    {
                        name: "Developer APIs",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                    {
                        name: "Blog",
                        externalLink: "/blog",
                    },
                    {
                        name: "Videos",
                        externalLink: "https://www.youtube.com/c/Gallabox/videos",
                    },
                    {
                        name: "Case Study",
                        externalLink: "/case-study",
                    },
                    {
                        name: "How-To Guides",
                        externalLink: "https://guides.gallabox.com/",
                    },
                ],
            },
        ],
    },
];

const DesktopFooter: FC<DesktopFooterProps> = ({ handleFooterLinkV2, noMarginTop, customFooterTop = false }) => {
    return (
        <footer
            className={`hidden lg:block bg-white text-gray-6000 ${noMarginTop ? "" : ""} ${customFooterTop ? "pt-14" : "desktop-footer lg:pt-36"} `}
            style={{ backgroundColor: customFooterTop ? "#001433" : "" }}
        >
            <div className="max-w-6xl w-full mx-auto flex flex-col">
                <div className="wave-bottom flex flex-col gap-10 w-full lg:flex-row lg:gap-0 justify-between">
                    {footerLinkData1.map((footerColumnData) => (
                        <div key={footerColumnData.title} className="flex w-48 flex-col gap-2 text-inherit text-sm mt-4">
                            {footerColumnData.columnItems.map((instance: any) => (
                                <React.Fragment key={instance.header}>
                                    <h6 className="mt-0 mb-0 capitalize font-medium tracking-wider text-xl" style={{ color: customFooterTop ? "#FFFFFF" : "#182242" }}>
                                        {instance.header}
                                    </h6>
                                    {instance.items.map((linkData: any, index: number) => {
                                        const lastItem = index === instance.items.length - 1;
                                        return (
                                            <a
                                                key={linkData.name}
                                                className={`text-inherit cursor-pointer leading-relaxed ${lastItem && "mb-5"}`}
                                                {...handleFooterLinkV2(linkData)}
                                                style={{ color: customFooterTop ? "#CCE6FF" : "#5D6B7E", width: "max-content" }}
                                            >
                                                {linkData.name}
                                                {linkData.isNew && (
                                                    <span className="new-tag" style={{ background: customFooterTop ? "#1BBA5C" : "", fontWeight: customFooterTop ? 600 : 0 }}>
                                                        New
                                                    </span>
                                                )}
                                            </a>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </div>
                    ))}

                    <div className="flex w-60 flex-col gap-2 text-inherit text-sm mt-4">
                        <h6 className="mt-0 capitalize font-medium tracking-wider text-xl" style={{ color: customFooterTop ? "#FFFFFF" : "#182242" }}>
                            Connect with us
                        </h6>
                        <div className="flex relative items-start">
                            <a
                                className="inline-flex m-0 items-center text-sm leading-relaxed "
                                style={{ color: customFooterTop ? "#CCE6FF" : "#666666" }}
                                target="_blank"
                                href="mailto:team@gallabox.com"
                                rel="noreferrer"
                            >
                                {customFooterTop ? <MdOutlineEmail size={20} /> : <img src="/footer-mail.png" width={"19px"} height={"15px"} alt="mail icon" />}
                                <p className="mx-2">team@gallabox.com</p>
                            </a>
                            <div>
                                <Image loading="lazy" src="https://assets.gallabox.com/gb-home/footer_robot.gif" alt="bot-gif" height={130} width={130} />
                            </div>
                        </div>

                        <div className="flex gap-3 mt-3 text-gray-800">
                            <SocialCTA
                                customFooter={customFooterTop}
                                name="Facebook"
                                href="https://www.facebook.com/gallaboxofficial"
                                icon={<FaFacebookF />}
                                brandColor="#1877F2"
                            />
                            <SocialCTA
                                customFooter={customFooterTop}
                                name="Instagram"
                                href="https://www.instagram.com/gallabox"
                                icon={<FaInstagram />}
                                brandColor="linear-gradient(212.78deg, #CB3CA9 14.73%, #FF6541 52.73%, #FFDC55 87.26%)"
                                gradient
                                gradientBorder="#FFDC80"
                            />
                            <SocialCTA customFooter={customFooterTop} name="Twitter" href="https://twitter.com/gallabox" icon={<FaTwitter />} brandColor="#1DA1F2" />
                            <SocialCTA
                                customFooter={customFooterTop}
                                name="LinkedIn"
                                href="https://www.linkedin.com/showcase/gallabox-crm"
                                icon={<FaLinkedinIn />}
                                brandColor="#0077B7"
                            />
                            <SocialCTA customFooter={customFooterTop} name="Youtube" href="https://www.youtube.com/c/Gallabox/videos" icon={<FaYoutube />} brandColor="#FF0000" />
                            <SocialCTA
                                customFooter={customFooterTop}
                                name="Whatsapp"
                                href="https://api.whatsapp.com/send/?phone=%2B917825877730&text=Hello+Gallabox+Sales+team+&type=phone_number&app_absent=0"
                                icon={<RiWhatsappFill />}
                                brandColor="#25D366"
                            />
                        </div>
                    </div>
                </div>
                <div className="wave-bottom flex flex-col gap-10 w-full lg:flex-row lg:gap-0 justify-between">
                    {footerLinkData2.map((footerColumnData) => (
                        <div key={footerColumnData.title} className="flex w-48 flex-col gap-2 text-inherit text-sm mt-4">
                            {footerColumnData.columnItems.map((instance: any) => (
                                <React.Fragment key={instance.header}>
                                    <h6 className="mt-0 mb-0 capitalize font-medium tracking-wider text-xl" style={{ color: customFooterTop ? "#FFFFFF" : "#182242" }}>
                                        {instance.header}
                                    </h6>
                                    {instance.items.map((linkData: any, index: number) => {
                                        const lastItem = index === instance.items.length - 1;
                                        return (
                                            <a
                                                key={linkData.name}
                                                className={`text-inherit cursor-pointer leading-relaxed ${lastItem && "mb-5"}`}
                                                {...handleFooterLinkV2(linkData)}
                                                style={{ color: customFooterTop ? "#CCE6FF" : "#5D6B7E" }}
                                            >
                                                {linkData.name}
                                                {linkData.isNew && (
                                                    <span className="new-tag" style={{ background: customFooterTop ? "#1BBA5C" : "", fontWeight: customFooterTop ? 600 : 0 }}>
                                                        New
                                                    </span>
                                                )}
                                            </a>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </div>
                    ))}

                    <div className="flex w-60 flex-col gap-2 text-inherit text-sm mt-4">
                        <div className="text-xl font-medium" style={{ color: customFooterTop ? "#FFFFFF" : "#182242" }}>
                            Smartest WhatsApp <br /> Automation Platform
                        </div>
                        <div className="pb-10" style={{ color: customFooterTop ? "#CCE6FF" : "#5D6B7E" }}>
                            Made with ❤️ in INDIA
                        </div>
                        <h6 className="mt-0 capitalize mb-0 font-medium tracking-wider text-xl" style={{ color: customFooterTop ? "#FFFFFF" : "#182242" }}>
                            Download the App
                        </h6>
                        <div className="flex items-center justify-center lg:justify-start">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.mangoleap.gallabox&utm_source=Landing%20Page"
                                className="track_playstore -ml-2"
                            >
                                <Image
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    alt="Get it on Google Play"
                                    width={160}
                                    height={67}
                                />
                            </a>
                            <a className="track_applestore" target="_blank" rel="noreferrer" href="https://apps.apple.com/in/app/gallabox/id1542957177">
                                <Image loading="lazy" src="/appstore-badge.svg" alt="Download on the App Store" width={138} height={53} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse items-center lg:items-start lg:flex-row justify-center relative py-4">
                    <div className="flex footer-border flex-col gap-3 items-center w-full">
                        <div className="max-w-6xl">{/*<img src="/up-down-line.png" />*/}</div>
                        <span className="text-gray-400 my-2 text-sm tracking-wide" style={{ color: customFooterTop ? "#CCE6FF" : "#5D6B7E" }}>
                            © {new Date().getFullYear()} Gallabox India Private Limited. All rights reserved.
                        </span>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .new-tag {
                    background-color: #25d366;
                    color: white;
                    font-size: 0.7em;
                    padding: 0.2em 0.5em;
                    border-radius: 3px;
                    margin-left: 5px;
                }
            `}</style>
        </footer>
    );
};

export default React.memo(DesktopFooter);
