import React, { useState, useEffect, forwardRef } from "react";
import Flag from "react-world-flags";
import { getCountryTelCode, Countries } from "./countries";

interface PhoneNumberInputProps {
    size?: "md";
    value?: string;
    country?: string;
    onChange: (...event: any[]) => void;
    placeholder?: string;
    [key: string]: any;
    onBlur: () => void;
}
const countryOptions = Countries.map(({ name, iso2 }) => ({
    label: name,
    value: iso2,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IntlPhoneInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({ value, country, onChange, placeholder, size, ...rest }, ref) => {
    IntlPhoneInput.displayName = "IntlPhoneInput";
    const [number, setNumber] = useState(value || "");
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country || "");
    const [countryCode, setCountryCode] = useState(getCountryTelCode(country) || "");

    useEffect(() => {
        setSelectedCountry(country);
        setCountryCode(getCountryTelCode(country));
    }, [country]);

    const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        const code = getCountryTelCode(value);
        const parsedNumber = `${code}${number}`;

        setCountryCode(code);
        setSelectedCountry(value);
        onChange(parsedNumber);
    };

    const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const reg = new RegExp("^[0-9]*$");
        const value = e.target.value;
        if (reg.test(value)) {
            const parsedNumber = `${countryCode} ${value}`;
            setNumber(value);
            onChange(parsedNumber);
        }
    };

    return (
        <div className="relative flex  w-full flex-wrap items-stretch">
            <span className="flex  items-center justify-center absolute h-12 w-16 text-base top-0 left-0 z-2 bg-emerald-50 hover:bg-emerald-100 cursor-pointer text-black">
                <select className="top-0 left-0 bottom-0 opacity-0 z-10 h-full w-full absolute" value={selectedCountry} onChange={onCountryChange}>
                    <option value="" />
                    {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="flex pl-2 mr-2 w-full items-center">
                    {selectedCountry ? (
                        <div className="mr-2 w-2/4 flex-1">
                            <Flag height="1rem" code={selectedCountry || ""} />
                        </div>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-4 inline-block align-middle flex-shrink-0" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                    )}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline-block align-middle flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </span>
            <input ref={ref} style={{ width: "100%", paddingLeft: "60px" }} type="tel" value={number} placeholder={placeholder} onChange={onPhoneNumberChange} {...rest} />
        </div>
    );
});
export default IntlPhoneInput;
