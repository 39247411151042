import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const setPostHeaders = (
    body: string | Record<string, unknown>,
    method: string = "POST"
): {
    method: string;
    headers: {
        Accept: string;
        "Content-Type": string;
    };
    body: string;
} => {
    const h = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    return {
        method,
        headers: h,
        body: typeof body === "string" ? body : JSON.stringify(body),
    };
};

export const parseJSON = (response: Response): Promise<any> => {
    return response.json();
};

export const checkStatus = (response: Response): Response => {
    const { status, statusText } = response;
    if (status >= 200 && status < 300) return response;
    throw new Error(statusText);
};

export const supabaseAPI = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    validateStatus: function (status) {
        return status >= 200 && status <= 399;
    },
});

export function setHeaders(): AxiosRequestConfig {
    const accessToken = process.env.NEXT_PUBLIC_ANON_KEY;
    const h: HeadersInit = {};
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
        h.apiKey = accessToken;
    }
    return {
        headers: h,
    };
}

const handleAxiosError = (err: AxiosError) => {
    if (err.response) {
        const error = (err.response?.data as any)?.error;
        const message = (err.response?.data as any)?.message;

        if (error ?? message) {
            throw new Error(error ?? message);
        }
    } else if (err.request) {
        throw new Error("Possibly your network got disconnected. Please check your internet");
    }
    throw err;
};

export function setRequestConfig(): AxiosRequestConfig {
    return { withCredentials: true };
}

export function getJSON<R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const requestConfig: AxiosRequestConfig = setHeaders();
    return supabaseAPI
        .get(url, { ...(config ?? {}), ...requestConfig })
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function postJSON<T extends Record<string, any>>(url: string, payload: T, config?: AxiosRequestConfig): Promise<any> {
    const headers: AxiosRequestConfig = setHeaders();
    return supabaseAPI
        .post(url, payload, {
            ...config,
            headers: {
                ...config?.headers,
                ...headers.headers,
                Authorization: config?.headers?.Authorization || headers.headers?.Authorization,
            },
        })
        .then((response) => response.data)
        .catch(handleAxiosError);
}
