export const featuresList = [
    {
        img: "/nav-icons/whatsapp-flows.svg",
        title: "WhatsApp Flows",
        description: "Facilitate Meaningful Customer Interactions with WhatsApp Flows",
        link: "/whatsapp-flows",
        isNew: true,
    },
    {
        img: "/nav-icons/ai.svg",
        title: "Gallabox Gen AI",
        description: "Harness the power of AI within WhatsApp",
        link: "/whatsapp-ai-chatbot",
        isNew: true,
    },
    {
        img: "/nav-icons/API.svg",
        title: "WhatsApp Business API",
        description: "Reach customer on their preferred messaging channel",
        link: "/whatsapp-business-api",
    },
    {
        img: "/nav-icons/Inbox.svg",
        title: "Shared Team Inbox",
        description: "Collaborate with team members effectively",
        link: "/whatsapp-shared-inbox",
    },
    {
        img: "/nav-icons/Bot.svg",
        title: "WhatsApp Chatbot",
        description: " Scale customer experiences, not your team size",
        link: "/whatsapp-chatbot",
        isNew: true,
    },
    {
        img: "/nav-icons/Drip-Marketing.svg",
        title: "Drip Marketing",
        description: "Send sequence of messages at intervals on WhatsApp",
        link: "/whatsapp-drip-marketing",
    },
    {
        img: "/nav-icons/Broadcast.svg",
        title: "WhatsApp Broadcast",
        description: "Launch marketing campaigns at scale",
        link: "/whatsapp-broadcast",
    },
    {
        img: "/nav-icons/wa-ads.svg",
        title: "Click to WhatsApp Ads",
        description: "Convert leads to deals using CTWA",
        link: "/click-to-whatsapp-ads",
    },
    {
        img: "/nav-icons/payments.svg",
        title: "Whatsapp Payments",
        description: "Automate payment collections on WhatsApp",
        link: "/whatsapp-payments",
    },
    {
        img: "/nav-icons/catalogs.svg",
        title: "WhatsApp Shop",
        description: "Setup Product Catalogs and start selling on WhatsApp in no time",
        link: "/build-your-shop-on-whatsapp",
        isNew: true,
    },
];

export const industryList = [
    {
        img: "/nav-icons/Education.svg",
        title: "Education",
        description: "Real-time communication solutions for better learning",
        link: "/whatsapp-chatbot-for-edutech",
    },
    {
        img: "/nav-icons/Real-Estate.svg",
        title: "Real Estate",
        description: "Recommend, promote and showcase listings more..",
        link: "/whatsapp-chatbot-for-real-estate",
        isNew: true,
    },
    {
        img: "/nav-icons/Travel.svg",
        title: "Travel",
        description: "Boost travel agency bookings on WhatsApp",
        link: "/whatsapp-chatbot-for-travel-and-tourism",
    },
    {
        img: "/nav-icons/E-commerce.svg",
        title: "E-commerce",
        description: "Engaging customers in real-time and creating interactive..",
        link: "/whatsapp-integrations/ecommerce",
    },
    {
        img: "/nav-icons/BFSI.svg",
        title: "BFSI",
        description: "Secure customer support & sales solutions for your business",
        link: "/banking-chatbots",
    },
    {
        img: "/nav-icons/healthcare.svg",
        title: "Healthcare",
        description: "Automate and manage appointment bookings efficiently",
        link: "/whatsapp-chatbot-for-health-wellness-brands",
    },
];

export const resourcesList = [
    {
        img: "/nav-icons/blog.svg",
        title: "Blog",
        description: "Reach customer on their preferred messaging channel",
        link: "/blog",
    },
    {
        img: "/nav-icons/docs.svg",
        title: "Docs",
        description: "A quick overview of Gallabox to help you understand & automate your business",
        link: "https://docs.gallabox.com/",
    },
    {
        img: "/nav-icons/Bot-Template.svg",
        title: "Bot Templates",
        description: "Build no-code bots to serve customers 24/7",
        link: "/whatsapp-chatbot-templates",
    },
    {
        img: "/nav-icons/Case-Study.svg",
        title: "Case Studies",
        description: "Learn how Gallabox is helping businesses meet business goals",
        link: "/case-study",
    },
    {
        img: "/nav-icons/e-book.svg",
        title: "Ebooks",
        description: "Your one-stop shop for all deep dives",
        link: "/resources/downloadables",
    },
    {
        img: "/nav-icons/how-to-guide.svg",
        title: "How-To Guides",
        description: "A step-by-step resource to master essential tasks effortlessly.",
        link: "https://guides.gallabox.com/",
    },
    {
        img: "/nav-icons/WhatsAppMessageTemplateGenerator.svg",
        title: "WhatsApp message templates",
        description: "500 Free messages by industry and use case",
        link: "/resources/free-whatsapp-message-templates",
    },
];

export const integrationList = [
    {
        img: "/nav-icons/odoo.svg",
        title: "Odoo",
        description: "Connect Odoo with Your WhatsApp Automation Platform",
        link: "/whatsapp-integrations/odoo",
        isNew: true,
    },
    {
        img: "/nav-icons/Zoho.svg",
        title: "Zoho",
        description: "Engage your Zoho contacts more efficiently with WhatsApp workflows.",
        link: "/whatsapp-integrations/zoho",
    },
    {
        img: "/nav-icons/hubspot-icon.svg",
        title: "Hubspot",
        description: "Unify communication by integrating HubSpot with your WhatsApp Business account",
        link: "/whatsapp-integrations/hubspot",
    },
    {
        img: "/nav-icons/Razorpay.svg",
        title: "RazorPay",
        description: " Close deals faster by making payments on WhatsApp easy with Razorpay",
        link: "/whatsapp-integrations/razorpay",
    },
    {
        img: "/nav-icons/zoho-books.svg",
        title: "Zoho Books",
        description: "Speed Up Your Payment Collection by Automating Zoho Book Workflows",
        link: "/whatsapp-integrations/zoho-books",
        isNew: true,
    },
];

export const freeToolsList = [
    {
        img: "/nav-icons/free-tool-menu/ai-template-generator.svg",
        title: "AI Template Generator ",
        description: "Create AI-generated templates with a text prompt",
        link: "/ai-whatsapp-template-generator",
    },
    {
        img: "/nav-icons/free-tool-menu/chat-widget.svg",
        title: "Chat Widget",
        description: "Add a chat button to your website so visitors can connect",
        link: "/whatsapp-chat-button",
    },
    {
        img: "/nav-icons/free-tool-menu/link-generator.svg",
        title: "Link Generator",
        description: "Connect with Your Customers Instantly Using WhatsApp Links",
        link: "/whatsapp-link-generator",
    },
    {
        img: "/nav-icons/free-tool-menu/qr-code.svg",
        title: "QR Code",
        description: "Get More Leads and Sales with a WhatsApp QR Code Generator",
        link: "/whatsapp-qr-code-generator",
    },
    {
        img: "/nav-icons/free-tool-menu/wa-pricing-calci.svg",
        title: "Pricing Calculator",
        description: "Estimate your WhatsApp messaging cost for free",
        link: "/whatsapp-conversation-pricing-calculator",
    },
];

export const partnersList = [
    {
        img: "/nav-icons/Reseller.svg",
        title: "Become a Partner",
        description: "Amplify your earning potential with Gallabox",
        link: "/gallabox-partner-program",
    },
    {
        img: "/nav-icons/Partner.svg",
        title: "Become an Affiliate",
        description: "A quick overview of Gallabox to help you understand & automate your business",
        link: "/affiliate-partner-program",
    },
];
