import React from "react";
import { BsChevronDown } from "react-icons/bs";
import MobileNavContent from "./MobileNavContent";
import { featuresList, freeToolsList, industryList, integrationList, resourcesList, partnersList } from "./navItemData";
import CtaButton from "./CtaButton";
import Image from "next/image";

interface MobileNavbarProps {
    handleLogin: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isKuwaitHeader: boolean;
    isWhatsappPageNav: boolean;
    customNavBar: boolean;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ handleLogin, isKuwaitHeader, isWhatsappPageNav, customNavBar = false }) => {
    const headerStyles = {
        color: "#2D3748",
    };
    return (
        <div>
            {!customNavBar && (
                <div className="lg:hidden text-gray-600 w-full mx-auto flex flex-col  mobile-footer mobile-navbar">
                    <div className="wave-bottom px-4 flex flex-col w-full">
                        <div className="flex flex-col w-auto flex-1 justify-end mb-4">
                            {!(isKuwaitHeader || isWhatsappPageNav) && (
                                <div id="feature" className="tab w-full overflow-hidden border-b-2 border-solid border-gray-100 tab-bottom-custom padding-custom">
                                    <input className="absolute hidden" id="product-menu1" type="checkbox" name="Company" />
                                    <label htmlFor="product-menu1" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                        <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                            Features
                                        </h6>
                                        <div className="expand-toggle-icon rounded-full flex items-center  h-8">
                                            <BsChevronDown />
                                        </div>
                                    </label>
                                    <div id="feature-tab" style={{ paddingTop: "0px" }} className=" tab-content overflow-hidden ">
                                        <div className="flex flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                            {featuresList.map((item) => (
                                                <MobileNavContent key={item.title} item={item} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isWhatsappPageNav && (
                                <React.Fragment>
                                    <div className="whatsapp-mobile-nav">
                                        <a href="whatsapp-business-automation#whatsapp-team-inbox">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider px-4 text-lg mb-4">WhatsApp Team Inbox</h6>
                                        </a>
                                    </div>
                                    <div className="whatsapp-mobile-nav">
                                        <a href="whatsapp-business-automation#whatsapp-chatbot">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider px-4 text-lg mb-4">WhatsApp Chatbot</h6>
                                        </a>
                                    </div>
                                    <div className="whatsapp-mobile-nav">
                                        <a href="whatsapp-business-automation#whatsapp-broadcast">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider px-4 text-lg mb-4">WhatsApp Broadcast</h6>
                                        </a>
                                    </div>
                                </React.Fragment>
                            )}

                            {!(isKuwaitHeader || isWhatsappPageNav) && (
                                <>
                                    <div className="tab w-full overflow-hidden border-solid border-b-2 border-gray-100 padding-custom">
                                        <input className="absolute hidden" id="product-menu2" type="checkbox" name="Company" />
                                        <label htmlFor="product-menu2" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                                Industry
                                            </h6>
                                            <div className="expand-toggle-icon rounded-full flex items-center h-8">
                                                <BsChevronDown />
                                            </div>
                                        </label>
                                        <div style={{ paddingTop: "0px" }} className="tab-content overflow-hidden ">
                                            <div className="flex  flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                                {industryList.map((item) => (
                                                    <MobileNavContent key={item.title} item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab w-full overflow-hidden border-solid border-b-2 border-gray-100 padding-custom">
                                        <input className="absolute hidden" id="product-menu3" type="checkbox" name="Company" />
                                        <label htmlFor="product-menu3" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                                Integrations
                                            </h6>
                                            <div className="expand-toggle-icon rounded-full flex items-center h-8">
                                                <BsChevronDown />
                                            </div>
                                        </label>
                                        <div style={{ paddingTop: "0px" }} className="tab-content overflow-hidden ">
                                            <div className="flex flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                                {integrationList.map((item) => (
                                                    <MobileNavContent key={item.title} item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab w-full overflow-hidden border-solid border-b-2 border-gray-100 padding-custom">
                                        <input className="absolute hidden" id="product-menu4" type="checkbox" name="Company" />
                                        <label htmlFor="product-menu4" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                                Resources
                                            </h6>
                                            <div style={{ paddingTop: "0px" }} className="expand-toggle-icon rounded-full flex items-center  h-8">
                                                <BsChevronDown />
                                            </div>
                                        </label>
                                        <div className="tab-content overflow-hidden ">
                                            <div className="flex flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                                {resourcesList.map((item) => (
                                                    <MobileNavContent key={item.title} item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab w-full overflow-hidden border-solid border-b-2 border-gray-100 padding-custom">
                                        <input className="absolute hidden" id="product-menu5" type="checkbox" name="Company" />
                                        <label htmlFor="product-menu5" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                                Free Tools
                                            </h6>
                                            <div style={{ paddingTop: "0px" }} className="expand-toggle-icon rounded-full flex items-center  h-8">
                                                <BsChevronDown />
                                            </div>
                                        </label>
                                        <div className="tab-content overflow-hidden ">
                                            <div className="flex flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                                {freeToolsList.map((item) => (
                                                    <MobileNavContent key={item.title} item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab w-full overflow-hidden border-solid border-b-2 border-gray-100 padding-custom">
                                        <input className="absolute hidden" id="product-menu6" type="checkbox" name="Company" />
                                        <label htmlFor="product-menu6" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl " style={headerStyles}>
                                                Partners
                                            </h6>
                                            <div style={{ paddingTop: "0px" }} className="expand-toggle-icon rounded-full flex items-center  h-8">
                                                <BsChevronDown />
                                            </div>
                                        </label>
                                        <div className="tab-content overflow-hidden ">
                                            <div className="flex flex-col gap-2.5 w-full text-inherit text-sm pb-4 mobile-navbar-content">
                                                {partnersList.map((item) => (
                                                    <MobileNavContent key={item.title} item={item} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab w-full overflow-hidden  border-solid border-gray-100 padding-custom">
                                <a href="/gallabox-partner-program" className="flex w-full leading-normal text-base cursor-pointer items-center">
                                    <h6 className="flex flex-row m-0 capitalize font-bold tracking-wider text-xl">
                                        Become a Partner
                                        <p className="font-medium text-base ">
                                            <span className="new-tag ">New</span>
                                        </p>
                                    </h6>
                                </a>
                            </div> */}
                                    <div className="tab w-full overflow-hidden  border-solid border-gray-100 padding-custom">
                                        <a
                                            href={isKuwaitHeader ? "whatsapp-business-kuwait#pricing" : isWhatsappPageNav ? "whatsapp-business-automation#pricing" : "/pricing"}
                                            className="flex w-full leading-normal text-base cursor-pointer items-center"
                                        >
                                            <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-xl" style={headerStyles}>
                                                Pricing
                                            </h6>
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mobile-navbar-button-block">
                            {isWhatsappPageNav ? (
                                <div>
                                    <button className="whatsapp-page-nav-btn">WhatsApp Us</button>
                                </div>
                            ) : (
                                <div className="flex">
                                    <button onClick={(e) => handleLogin(e)} className="mob-login">
                                        Login
                                    </button>
                                    <CtaButton type="large" text="Start Free Trial" customClass="text-lg ml-5 font-semibold" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-3 py-4 mobile-footer-bg">
                        <span className="text-gray-400 text-xs tracking-wide text-center">© {new Date().getFullYear()} Gallabox Inc. All rights reserved.</span>
                        <Image src={"https://assets.gallabox.com/gb-home/footer_robot.gif"} height={66} width={66} alt="robot gif" />
                    </div>
                    <style jsx>{`
                        .wave-bottom {
                            border-bottom: 1px solid #e5e5e5;
                        }

                        .new-tag {
                            background-color: #25d366;
                            color: white;
                            font-size: 0.7em;
                            padding: 0.3em 0.5em;
                            border-radius: 3px;
                            margin-left: 3px;
                        }

                        .tab-content {
                            padding-top: 0.625rem;
                            max-height: 0.625rem;

                            -webkit-transition: all 200ms ease-in;
                            -o-transition: all 200ms ease-in;
                            transition: all 200ms ease-in;
                        }

                        .tab input:checked ~ .tab-content {
                            max-height: 100vh;
                        }

                        .tab input + label {
                            transition: all 200ms ease-in;
                            font-weight: 400;
                        }

                        .tab input + label:hover {
                            color: #333333;
                        }

                        .tab input + label > .expand-toggle-icon {
                            transition: all 200ms ease-in;
                            color: #ffffff;
                        }

                        .tab input:checked + label {
                            color: #000;
                            font-weight: 500;
                        }

                        .tab input:checked + label > .expand-toggle-icon {
                            transform: rotate(-180deg);
                        }
                    `}</style>
                </div>
            )}
        </div>
    );
};

export default React.memo(MobileNavbar);
